.Header {
  margin-bottom: 6vw;
  padding-top: 15.3vw;
  text-align: center;
  color: #00a3ed;
  font-family: Poppins;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.7vw; /* 64.5px */
  letter-spacing: 0.86px;
}

.CardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: flex-start;
  align-content: space-between;
  margin: auto;

  flex-wrap: wrap;
  row-gap: 3vw;
  column-gap: 4vw;
  width: 85vw;

  min-height: 63vw;
}

.Card {
  width: 25.4vw;
  min-height: 30vw;
}

.Card img {
  height: 15.6vw;
}

.CardHeader {
  padding-top: 2vw;
  color: #37393f;
  font-family: Poppins;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.1vw;

  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.CardDescription {
  color: #7d7d7d;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5vw;
  padding-top: 0.7vw;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.CardFooter {
  padding-top: 1.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CardFooterUrl {
  color: #00a3ed;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}
.CardFooterDate {
  color: rgba(42, 56, 59, 0.44);
  font-family: Poppins;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2vw;
}

.PageControl {
  display: flex;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  column-gap: 3vw;
  margin-top: 3vw;
  margin-bottom: 4vw;
}

.NextButtonDisabled {
  background: #00a2ed7b !important;
}

.NextButton {
  width: 8.1vw;
  height: 3.7vw;
  background: #00a3ed;
  border: 1px solid #00a3ed;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .CardContainer {
    align-content: center;
    flex-direction: column;
    row-gap: 10vw;
    margin-bottom: 12.5vw;
  }

  .CardDescription {
    font-size: 3.7vw;
    line-height: 4.8vw;
  }

  .CardFooter {
    padding-top: 3.4vw;
  }

  .CardFooterDate {
    font-size: 2.9vw;
    line-height: 150.6%;
  }

  .CardFooterUrl {
    font-size: 3.2vw;
    text-transform: capitalize;
  }

  .CardHeader {
    font-size: 5.6vw;
    line-height: 127%;
  }

  .Header {
    font-size: 5.6vw;
    line-height: 150%; /* 31.5px */
    letter-spacing: 0.42px;

    margin-bottom: 10.6vw;
    padding-top: 27vw;
  }

  .Card {
    width: 88vw;
    min-height: 104vw;
  }

  .Card img {
    width: 88vw;
    height: 53.8vw;
  }
}
