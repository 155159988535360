.PageHeaderContainer {
  height: 26.5vw;
  padding-top: 10.3vw;
}

.PageHeaderImage {
  width: 100%;
  position: absolute;
  left: 0;
  object-fit: cover;
  z-index: 0;
}

.Header {
  width: 48vw;
  height: 100%;
  background-color: rgba(0, 163, 237, 0.62);
  backdrop-filter: blur(9.5px);

  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HeaderText {
  color: #e6f4f1;
  font-family: Poppins;
  font-size: 3.4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.8vw;

  width: 23.4vw;
  height: 8.2vw;
}

@media screen and (max-width: 600px) {
  .PageHeaderContainer {
    height: 38.4vw;
    padding-top: 16.4vw;
  }

  .PageHeaderImage {
    height: 39vw;
  }

  .HeaderText {
    font-size: 5.6vw;
    line-height: 125%;
    width: 37.3vw;
  }

  .Header {
    width: 48vw;
  }
}
