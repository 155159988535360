.MainContainer {
  background: #94dbfb52;
  display: flex;
  height: 21.5vw;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;

  color: #00a3ed;
  font-family: Poppins;
  font-size: 1.2vw;
  row-gap: 0.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 31.5px */
  letter-spacing: 0.42px;
}

.Follow {
  color: #00a3ed;
  text-align: center;
  font-family: 'Jost';
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3vw; /* 160% */
  letter-spacing: 1.35px;
  /* width: 15.3vw; */
}

.SocialLogo {
  width: 1.7vw;
  height: 1.7vw;
  margin-right: 1vw;
}

.Navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 30vw;
  height: 6.9vw;
  justify-content: center;
  /* column-gap: 2vw; */
  margin-left: 5vw;
}

.SocialLinks {
  display: flex;
  flex-direction: row;
  /* margin-left: 10.7vw; */
  width: 29.5vw;
  height: 1.8vw;
  justify-content: space-evenly;
  align-items: center;
}

.Info {
  height: 12.5vw;
  display: flex;
  width: 84vw;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4.2vw;
  border-bottom: 2px solid #00a3ed;
}

.Logo {
  align-items: center;
  width: 11.5vw;
  height: 3.2vw;
}

.Copyright {
  color: #00a3ed;
  font-family: Poppins;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 27px */
  padding-top: 1.8vw;
}

.Navigation a {
  text-decoration: none;
  color: #00a3ed;
}

@media screen and (max-width: 600px) {
  .MainContainer {
    height: 98.6vw;
  }
  .Logo {
    width: 27.2vw;
    height: 7.4vw;
  }

  .Info {
    flex-direction: column;
    padding-top: 5.3vw;
    height: auto;
    padding-bottom: 9vw;
    width: 85.8vw;
    justify-content: flex-start;
    border-bottom: 0.6px solid #00a3ed;
  }
  .Navigation {
    flex-direction: column;
    margin-left: 0;
    height: 35vw;
    width: 100%;
    justify-content: center;
    row-gap: 3.2vw;
    padding-top: 7vw;
  }

  .Row {
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.24px;
    row-gap: 3.2vw;
  }

  .SocialLinks {
    padding-top: 8vw;
    width: 100%;
    margin-left: 0;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .Follow {
    font-size: 3vw;
    text-align: left;
    font-weight: 700;
    line-height: 4px;
    width: 100%;
    letter-spacing: 0.99px;
  }

  .SocialLogo {
    width: 7.4vw;
    height: 7.4vw;
    padding-right: 4.5vw;
  }

  .SocialLogoContainer {
    flex-direction: column;
    padding-top: 4.5vw;
    width: auto;
  }

  .Copyright {
    color: #00a3ed;
    font-family: Poppins;
    font-size: 2.4vw;
    line-height: 180%; /* 27px */
    padding-top: 4.5vw;
  }
}
