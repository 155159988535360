.Header {
  margin-bottom: 6vw;
  padding-top: 15.3vw;
  text-align: center;
  color: #00a3ed;
  font-family: Poppins;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.7vw; /* 64.5px */
  letter-spacing: 0.86px;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vw;
  width: 19vw;
  border-radius: 1.15vw;
  transition: transform 0.3s; /* Animation */
}

.Card img {
  height: 27vw;
}

.CardHeader {
  text-align: center;
  position: relative;

  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1.15vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ViewOnHoverDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 0.4vw;
  min-height: 2.1vw;
}

.ViewOnHoverDetails div {
  width: 14.1vw;
}

.Card .ViewOnHover .CardDetailsVector {
  height: 1vw;
  padding-top: 0;
}

.ViewOnHover {
  display: none;
  padding-top: 1vw;
  padding-bottom: 1vw;
  /* height: 3.7vw; */
  z-index: 1;
  position: relative;
  bottom: 11.8vw;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  color: #103a50;
  text-align: center;
  font-family: Inter;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1vw; /* 133.333% */
}

.Card .Description {
  padding-top: 0.5vw;
  /* height: 3.7vw; */

  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1.15vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  min-height: 3.4vw;
}

.Card:hover .ViewOnHover {
  display: block;
}

.CardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 87vw;

  /* justify-content: center; */
  margin: auto;

  flex-wrap: wrap;
  row-gap: 2.9vw;
  column-gap: 3.4vw;
}

.Investors {
  margin-top: 5.4vw;
  margin-bottom: 25vw;
  position: relative;
}

.Investors .InvestorsImageMobile {
  display: none;
}

.Investors .InvestorsImageDesktop {
  width: 100%;
  height: 25vw;
  position: absolute;
  left: 0;
  object-fit: cover;
  z-index: 0;
}
.Investors .InvestorsHeader {
  color: #00a3ed;
  font-family: Poppins;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 79.5px */
  letter-spacing: 1.06px;

  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.1vw;
}

@media screen and (max-width: 600px) {
  .Investors .InvestorsImageMobile {
    width: 100%;
    height: 140vw;
    position: absolute;
    left: 0;
    object-fit: cover;
    z-index: 0;
    display: block;
  }

  .Investors {
    margin-top: 14.6vw;
    margin-bottom: 145vw;
  }

  .Investors .InvestorsImageDesktop {
    display: none;
  }
  .Investors .InvestorsHeader {
    font-size: 5.6vw;
    line-height: 150%;
    letter-spacing: 0.42px;

    padding-top: 9vw;
    margin-bottom: 15vw;
  }

  .CardContainer {
    flex-direction: column;
    row-gap: 16vw;
  }

  .Card {
    height: auto;
  }

  .Card .Description {
    width: 71.7vw;
    font-size: 4vw;
    line-height: 5.3vw;
  }

  .Card .ViewOnHover .CardDetailsVector {
    width: 4vw;
    height: 3vw;
    padding-bottom: 0;
  }

  .ViewOnHoverDetails div {
    font-size: 15px;
    line-height: 20px;
    width: 100%;
  }

  .ViewOnHoverDetails {
    padding-top: 2vw;
  }

  .ViewOnHover {
    display: block;
    width: 53vw;
    position: static;
    padding-top: 3vw;
  }

  .CardHeader {
    text-align: center;
    font-size: 5.3vw;
    line-height: 7vw;
    width: 73.8vw;
    letter-spacing: -1px;
  }

  .Header {
    padding-top: 27vw;
    margin-bottom: 5vw;
    font-size: 5.6vw;
    letter-spacing: 0.42px;
  }

  .Card img {
    height: 76.53vw;
    width: 54.1vw;
    padding-bottom: 2.6vw;
  }
}
