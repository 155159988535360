.SectionImage {
  width: 41.7vw;
  height: 43.4vw;
}

.SectionLeftImage {
  width: 42vw;
  height: 35vw;
}

.BubbleImage {
  width: 47.2vw;
}

.TechnologyContent {
  display: flex;
  flex-direction: column;
}

.Bubble {
  position: relative;
}

.BubbleText {
  position: absolute;
  width: 33.4vw;

  top: 8.4vw;
  left: 7.2vw;
}
.BubbleTextLeft {
  position: absolute;
  top: 8.4vw;
  left: 12vw;
  width: 25vw;
}

.BubbleTextHeader {
  color: #00a3ed;
  font-family: Poppins;
  font-size: 1.9vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.3vw; /* 121.212% */
  margin-bottom: 2.3vw;
}

.BubbleTextPoints {
  color: #103a50;
  font-family: Poppins;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
  width: 32vw;
}
.BubbleTextLeftPoints {
  color: #103a50;
  font-family: Poppins;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2vw;
  width: 24vw;
}

ul {
  list-style: url("../../assets/bullet-point.svg");
  padding-inline-start: 0px !important;
}

.Gallery_1,
.Gallery_2 {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 4.4vw;
  margin-bottom: 4.4vw;
}

.Gallery_1 img {
  width: 40vw;
  height: 30vw;
}
.Gallery_2 img {
  width: 24vw;
  height: 24vw;
}

.SectionHeader {
  color: #00a3ed;
  font-family: Poppins;
  font-size: 1.9vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.3;
}

.Section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding-top: 6.7vw;
  padding-bottom: 6.7vw;
}

.SectionLeft {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding-top: 6.7vw;
  padding-bottom: 6.7vw;
  background-color: #edf9fe;
}

.ResultSection {
  display: none;
  /* display: flex; */
  flex-direction: column;
  background-color: #edf9fe;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding-top: 6.7vw;
  margin-bottom: 9vw;
}

@media screen and (max-width: 600px) {
  .Section {
    flex-direction: column;
  }

  .SectionLeft {
    flex-direction: column-reverse;
  }

  .BubbleImage {
    display: none;
  }

  .BubbleText {
    position: static;
    width: 77.3vw;
    text-align: center;
  }
  .BubbleTextLeft {
    position: static;
    width: 77.3vw;
    text-align: center;
  }

  .BubbleTextPoints {
    width: 73.8vw;
    font-size: 3.7vw;
    line-height: 4.8vw;
    text-align: left;
    margin-left: 5vw;
  }

  .SectionImage {
    width: 65vw;
    height: 65vw;
    padding-bottom: 9.3vw;
  }

  .SectionLeftImage {
    width: 70vw;
    height: 58.4vw;
    padding-bottom: 9.3vw;
  }

  .SectionHeader {
    font-size: 4vw;
    line-height: 5.3vw;
    width: 77.3vw;
    text-align: center;
  }

  .Gallery_1, .Gallery_2 {
    flex-direction: column;
    align-items: center;
    row-gap: 9.6vw;
    margin-top: 10vw;
  }

  .Gallery_2 {
    margin-bottom: 12.5vw;
  }

  .Gallery_1 img {
    width: 74.1vw;
    height: 55.4vw;
  }

  .Gallery_2 img {
    width: 74.1vw;
    height: 74.6vw;
  }

  .BubbleTextLeftPoints {
    width: 73.8vw;
    font-size: 3.7vw;
    line-height: 4.8vw;
    text-align: left;
    margin-left: 5vw;
  }

  .BubbleTextHeader {
    font-size: 4vw;
    line-height: 5.3vw;
  }
}
