.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1vw;
}

.HeaderContainer {
  height: 58vw;
  position: relative;
  padding-top: 10.3vw;
}

.HeaderContainer video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  object-fit: cover;
  z-index: 0;
}

.TaglineBody {
  height: 9.5vw;
  width: 49.1vw;
  margin-top: 2.3vw;
  text-align: left;
  color: #000;
  font-family: Poppins;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5vw; /* 146.667% */
}

.Introduction {
  height: 98vw;
  position: relative;
  margin-bottom: 9.2vw;
}

.Introduction .WomenBreathing {
  width: 100%;
  height: 60%;
  position: absolute;
  left: 0;
  object-fit: cover;
  z-index: 0;
}
.Introduction .ContactUsBackground {
  width: 83%;
  height: 52%;
  position: absolute;
  top: 54vw;
  object-fit: cover;
  z-index: 0;

  left: 0;
  right: 0;
  margin: auto;
}

.Tagline {
  z-index: 1;
  position: relative;

  width: 60vw;
  height: 17vw;

  padding-left: 7vw;
  padding-top: 10vw;
}

.TaglineText {
  color: #00a3ed;
  font-family: 'Readex Pro';
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 6vw; /* 110.87% */
  text-align: left;
}
.IntroductionText {
  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  height: 18vw;

  right: 0;
  padding-top: 17.4vw;
  padding-right: 8.3vw;
  margin-bottom: 7.8vw;
}

.ContactUs {
  z-index: 1;
  position: relative;

  padding-top: 16vw;
  padding-left: 17vw;

  /* height: 18vw; */
  width: 65.8vw;
}

.ContactUsHeader {
  color: #00a3ed;
  font-family: Poppins;
  text-align: left;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 64.5px */
  letter-spacing: 0.86px;
  width: 29.3vw;
}

.FormGroup {
  padding-top: 3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1vw;
}

.VerticalFormGroup {
  display: flex;
  justify-content: space-between;
}

.FormSubmit {
  border-radius: 10px;
  border: 3px solid #ddf3fe;
  background: #00a3ed;
  width: 10.4vw;
  height: 3.4vw;

  color: #ddf3fe;
  text-align: center;
  font-family: 'Jost';
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 800;
  line-height: 1.3vw; /* 150% */
  letter-spacing: 1px;

  margin-top: 3.8vw;

  align-items: center;
  display: flex;
  justify-content: space-around;

  text-transform: uppercase;
}

.ContactUsForm textarea {
  height: 14.3vw;
  width: 64.5vw;
  position: relative;
  border: 5px solid white;
  border-radius: 5px;
}
.ContactUsForm input {
  width: 26.5vw;
  height: 3vw;
  border: 5px solid white;
  border-radius: 5px;
}
.ContactUsForm label {
  color: #00a3ed;
  font-family: 'Ubuntu';
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

.IntroductionTextHeader {
  color: #00a3ed;
  text-align: right;
  font-family: Poppins;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.3vw; /* 121.212% */

  width: 43.5vw;
  height: auto;
}

.IntroductionTextBody {
  color: #103a50;
  text-align: right;
  font-family: Poppins;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8vw; /* 160% */

  margin-top: 2.7vw;
  width: 35vw;
  height: 9vw;
}

.TaglineButton {
  width: 13vw;
  height: 4vw;

  margin-top: 5.3vw;
  align-items: center;
  display: flex;
  justify-content: space-around;

  border-radius: 5px;
  background: #1f61fd;
  border-color: #1f61fd;

  color: #e6fdff;
  font-family: 'Jost';
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  cursor: pointer;
}

a {
  text-decoration: none;
}

.Introduction .ContactUsBackgroundMobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .Introduction .ContactUsBackground {
    display: none;
  }

  .ContactUs {
    padding-top: 18vw;
    padding-left: 15.4vw;

    width: 70vw;
    height: 119.2vw;
  }

  .FormGroup {
    row-gap: 2.4vw;
    padding-top: 5.6vw;
  }

  .VerticalFormGroup {
    flex-direction: column;
    padding-top: 4vw;
  }

  .FormSubmit {
    margin-top: 6.6vw;
    font-size: 3vw;
    line-height: 6.4vw;
    letter-spacing: 1.15px;
    width: 24.5vw;
    height: 10vw;
  }

  .ContactUsForm label {
    font-size: 2.9vw;
    letter-spacing: 0.55px;
    text-transform: uppercase;
  }

  .ContactUsForm textarea {
    width: 69.6vw;
    height: 29.8vw;
  }

  .ContactUsForm input {
    width: 69vw;
    height: 9vw;
  }

  .ContactUsHeader {
    width: 29.6vw;
    font-size: 5.3vw;
    line-height: 150%; /* 30px */
    letter-spacing: -0.2px;
  }

  .Introduction .ContactUsBackgroundMobile {
    display: block;
    width: 89.6vw;
    height: 139vw;
    position: absolute;
    left: 0;
    object-fit: cover;
    z-index: 0;
    bottom: -2vw;
    left: 0;
    right: 0;
    margin: auto;
  }

  .IntroductionText {
    height: 38.1vw;
    padding-top: 12vw;
    padding-right: 5vw;
  }

  .IntroductionTextHeader {
    font-size: 3.2vw;
    line-height: 4vw;
    width: 50vw;
  }

  .IntroductionTextBody {
    font-size: 2.6vw;
    line-height: 4vw;
    width: 52vw;
    height: auto;
    margin-top: 2.1vw;
  }

  .Introduction {
    height: 203vw;
  }

  .Introduction .WomenBreathing {
    height: 72.2vw;
  }

  .HeaderContainer {
    height: 80vw;
    padding-top: 16.4vw;
  }

  .Tagline {
    width: 64.2vw;
    height: 72vw;
    padding-top: 6.4vw;
    padding-left: 5.6vw;
  }

  .TaglineBody {
    font-size: 2.6vw;
    line-height: 4vw;
    width: 58.4vw;
    height: 20vw;
    margin-top: 3vw;
  }

  .TaglineButton {
    margin-top: 6vw;
    width: 23.2vw;
    height: 7.7vw;
    font-size: 2.5vw;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .TaglineText {
    font-size: 6.6vw;
    width: 61vw;
    line-height: 7.4vw;
  }
}
