.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 26vw;
  width: 20.3vw;
}

.Card img {
  height: 10vw;
}

.RoadmapHeader {
  margin-bottom: 6.6vw;
  padding-top: 15.3vw;
  text-align: center;
  color: #00a3ed;
  font-family: Poppins;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.7vw; /* 64.5px */
  letter-spacing: 0.86px;
}

.Card .Header {
  width: 19.5vw;
  /* height: 5vw; */
  text-align: center;
  padding-top: 2.8vw;

  color: #00a3ed;
  text-align: center;
  font-family: Poppins;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4vw; /* 143.333% */
}

.Card .Description {
  width: 20.3vw;
  /* height: 3.7vw; */

  color: #103a50;
  text-align: center;
  font-family: Poppins;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8vw;
  padding-top: 0.5vw;
  white-space: pre-wrap;
}

.CardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 92.7vw;
  height: 27vw;

  justify-content: space-around;
  margin: auto;
}

.RoadmapImageMobile {
  display: none;
}

.RoadmapBackground {
  display: none;
}

.RoadmapImage {
  width: 90vw;
  height: 24.8vw;
  margin-bottom: 5.6vw;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {

  .RoadmapBackground {
    width: 100%;
    position: absolute;
    top: 38.6vw;
    z-index: -10;
  }
  
  .RoadmapHeader {
    font-size: 5.6vw;
    line-height: 150%;
    letter-spacing: 0.42px;
    margin-bottom: 16vw;
    padding-top: 27vw;
  }

  .RoadmapImage {
    display: none;
  }

  .RoadmapImageMobile {
    display: block;
    width: 87.2vw;
    height: 348.5vw;
    margin-bottom: 17vw;

    margin-left: auto;
    margin-right: auto;
  }

  .CardContainer {
    flex-direction: column;
    width: 74.4vw;
    height: 300vw;
  }

  .Card img {
    height: 35vw;
  }

  .Card {
    width: 73.3vw;
    height: 66.1vw;
  }

  .Card .Description {
    font-size: 3.7vw;
    line-height: 4.8vw;
    width: 73.3vw;
    padding-top: 2.1vw;
  }

  .Card .Header {
    font-size: 4vw;
    font-weight: 500;
    line-height: 5.3vw;
    width: 73vw;
  }
}
