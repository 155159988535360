.NavBar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  width: 89vw;
  align-items: center;
  height: 8.3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 7vw;
  padding-right: 4vw;
  background-color: #edf9fe;
}

.Hamburger {
  display: none;
}

.SwazaLogo {
  width: 20vw;
}

.HamburgerOptions {
  display: none;
}

.NavBarOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3vw;

  font-family: "Ubuntu";
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.NavBarOptions a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 600px) {
  .NavBarOptions {
    display: none;
  }

  .HamburgerOptions {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 62.4vw;
    position: absolute;
    z-index: 10;
    left: 0;
    border-radius: 3px;
    background: #68bee2;
    justify-content: space-evenly;
    top: 16vw;
  }

  .HamburgerOptionText {
    height: 12.2vw;
    text-align: center;
    display: table-cell;
    width: 100vw;
    vertical-align: middle;
    border-bottom: 0.5px solid white;

    color: #fff;
    font-family: "Ubuntu";
    font-size: 4.2vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  .HamburgerOptions a {
    text-decoration: none;
  }

  .Hamburger {
    display: block;
    width: 5vw !important;
    height: 4.2vw;
  }

  .NavBar {
    height: 14.4vw;
    padding-left: 4.2vw;
    padding-right: 5.8vw;
  }

  .SwazaLogo {
    width: 34.4vw !important;
    height: 7vw;
  }
}
